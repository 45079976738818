import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import MainTemplate from "templates/MainTemplate";
import styled from "styled-components";
import ArticleElement from "components/ArticleElement/ArticleElement";
import { Helmet } from "react-helmet";

const Background = styled.div`
  position: relative;
  padding: 60px 0;
  &:after {
    content: "";
    margin-top: 40px;
    background-color: ${({ theme }) => theme.colors.grey};
    z-index: -100;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  /* background-color: ${({ theme }) => theme.colors.grey}; */
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const BlogIndex = () => {
  const data = useStaticQuery(graphql`
    query AllPosts {
      allDatoCmsArticle(sort: { order: DESC, fields: meta___publishedAt }) {
        edges {
          node {
            title
            slug
            meta {
              publishedAt(formatString: "DD-MM-YYYY")
            }
            excerpt
            thumbnail {
              url
            }
          }
        }
      }
    }
  `);
  const posts = data.allDatoCmsArticle.edges.map(({ node }) => node);
  return (
    <MainTemplate>
      <Helmet>
        <title>Blog - Adwokat Adrianna Firlej-Koniecka</title>
      </Helmet>
      <Background>
        <Wrapper>
          {posts.length ? (
            posts.map((post) => (
              <ArticleElement
                key={post.slug}
                title={post.title}
                excerpt={post.excerpt}
                link={`/blog/${post.slug}`}
                thumbnail={post.thumbnail?.url}
              />
            ))
          ) : (
            <p>Pierwszy post pojawi się wkrótce</p>
          )}
        </Wrapper>
      </Background>
    </MainTemplate>
  );
};

export default BlogIndex;
